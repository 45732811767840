const config = {
  apiGateway: {
    REGION: 'us-west-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://obn3vclp39.execute-api.us-west-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://tmj6f0lxak.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://rpjirdlfib.execute-api.us-west-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.11.2',
    HOSTNAME: 'https://locations.wecomplai.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.wecomplai.forwoodsafety.com',
    WEBSOCKET: 'wss://hmys0usw60.execute-api.us-west-2.amazonaws.com/prod'
  },
};

export default config;